import { EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA } from "domain/entity/DocumentPreview/DocumentPreviewSearchCriteria";
import { useDocumentPreviewDetailVM } from "presentation/hook/DocumentPreview/useDocumentPreviewDetailVM";
import { useDocumentPreviewDetailTracked } from "presentation/store/DocumentPreview/DocumentPreviewDetailProvider";
import { useDocumentPreviewTracked } from "presentation/store/DocumentPreview/DocumentPreviewProvider";
import { memo, useCallback, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import { DocumentPreviewDetailHeaderPanel } from "./DocumentPreviewDetailHeaderPanel";
import DocumentPreviewDetailTitleBar from "./DocumentPreviewDetailTitleBar";
import { DocumentPreviewDraftInvoiceListPanel } from "./RightPanel/DocumentPreviewDraftInvoiceListPanel";
import DocumentPreviewDetailTablePanel from "./Table/DocumentPreviewDetailTablePanel";


const DocumentPreviewDetailMaintenance:React.FC = () => {
    const documentPreviewDetailVM = useDocumentPreviewDetailVM();
    const [documentPreviewState] = useDocumentPreviewTracked();
    const [documentPreviewDetailState] = useDocumentPreviewDetailTracked();
    const {currentSelectedData} = documentPreviewState;
    const {isShowMoveToPanel} = documentPreviewDetailState;
    const [isLoading, setIsLoading] = useState(true);


    const initialScreen = useCallback(async() => {
        setIsLoading(true);
        let tmmId = currentSelectedData.id as number;
        try {
            let newSearchCriteria = {...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA} 
                newSearchCriteria = { ...newSearchCriteria, 
                    docHdrId:tmmId, 
                };
            const results = await Promise.allSettled([
                documentPreviewDetailVM.onPageInit(currentSelectedData),
                documentPreviewDetailVM.searchInvItemDtl(newSearchCriteria),
            ]);
            results?.forEach((result, index) => {
                if (index === 0 && result.status === 'fulfilled') {

                  } else if (index === 1 && result.status === 'rejected') {

                  }
            });
            setIsLoading(false);
        } catch(error) {
        }
    }, [currentSelectedData, documentPreviewDetailVM]);

    useEffect(() => {
            initialScreen();
    }, [initialScreen])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <>
        <div className={`main-comp-wrapper${isShowMoveToPanel ? '' : ' im-hide-side-form-draggable'}`}>
           <DocumentPreviewDetailTitleBar/>
           <DocumentPreviewDetailHeaderPanel/> 
            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={isShowMoveToPanel?"60%":"100%"}
                rightSectionWidth={isShowMoveToPanel?"40%":"0%"}
                leftChildren={<DocumentPreviewDetailTablePanel/>}
                rightChildren={<DocumentPreviewDraftInvoiceListPanel/>} 
                />
        </div>
    </>
}

export default memo(DocumentPreviewDetailMaintenance);