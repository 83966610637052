import { DocumentEnquiryConstant } from "presentation/constant/DocumentEnquiry/DocumentEnquiryConstant";
import { useDocumentPreviewVM } from "presentation/hook/DocumentPreview/useDocumentPreviewVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useDocumentPreviewTracked } from "presentation/store/DocumentPreview/DocumentPreviewProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { memo, useCallback, useMemo } from "react";
import { DialogModal, HPHButton, IconButton } from "veronica-ui-component/dist/component/core";

const DocumentPreviewFirstPrintModal: React.FC = () => {
    const [documentPreviewState] = useDocumentPreviewTracked();
    const documentPreviewVM = useDocumentPreviewVM();
    const messageBarVM = useMessageBarVM();

    const { searchStatus, selectedRows, docFirstPrintCriteria, isShowPrintPad, dynamicOptions, searchCriteria } = documentPreviewState;

    const MODAL_CONST = DocumentEnquiryConstant.Modal;

    const { printQueueDropdownOptions, fileLocationDropdownOptions } = dynamicOptions;
    const [anaInfoState] = useANAInfoTracked();
    const { userName } = anaInfoState;

    const onPrintAndSentClicked = useCallback(() => {
        const firstPrintIds = selectedRows?.map(dtl => dtl.id) as number[];
        documentPreviewVM.onPrintAndSent(userName, firstPrintIds, selectedRows, docFirstPrintCriteria, searchCriteria, searchStatus).then((result) => {

            if (result && result["finalizeDocSucess"]) {
                messageBarVM.showSuccess(result["finalizeDocSucess"]?.toString());
            } else if (result && result["finalizeDocFail"]) {
                messageBarVM.showError(result["finalizeDocFail"]?.toString());
            }
        }).catch(() => {

        });
    }, [docFirstPrintCriteria, documentPreviewVM, messageBarVM, searchCriteria, searchStatus, selectedRows, userName]);

    const memoFinalizedBy = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={''}
                inputType="freeText"
                width="180px"
                mode={'single'}
                sort={false}
                value={docFirstPrintCriteria.finalizedBy}
                onChange={(e: any) => documentPreviewVM.onModalDropdownChange(e, 'finalizedBy')}
                options={documentPreviewState.dynamicOptions.finalizedByDropdownOptions} />
        </div>
        , [docFirstPrintCriteria.finalizedBy, documentPreviewState.dynamicOptions.finalizedByDropdownOptions, documentPreviewVM])

    const memoInvPrinter = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label=''
                width="150px"
                inputType="freeText"
                placeholder="Printer"
                value={docFirstPrintCriteria.invPrinter}
                onChange={(e: any) => documentPreviewVM.onModalDropdownChange(e, 'invPrinter')}
                options={printQueueDropdownOptions} />
        </div>
        , [docFirstPrintCriteria.invPrinter, documentPreviewVM, printQueueDropdownOptions])

    const memoInvFileLoc = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label=''
                width="150px"
                inputType="freeText"
                placeholder="File Location"
                value={docFirstPrintCriteria.invFileLoc}
                onChange={(e: any) => documentPreviewVM.onModalDropdownChange(e, 'invFileLoc')}
                options={fileLocationDropdownOptions} />
        </div>
        , [docFirstPrintCriteria.invFileLoc, documentPreviewVM, fileLocationDropdownOptions])

    const memoSuppDocPrinter = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label=''
                width="150px"
                inputType="freeText"
                placeholder="Printer"
                value={docFirstPrintCriteria.suppPrinter}
                onChange={(e: any) => documentPreviewVM.onModalDropdownChange(e, 'suppPrinter')}
                options={printQueueDropdownOptions} />
        </div>
        , [docFirstPrintCriteria.suppPrinter, documentPreviewVM, printQueueDropdownOptions])

    const memoSuppDocFileLoc = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label=''
                width="150px"
                inputType="freeText"
                placeholder="File Location"
                value={docFirstPrintCriteria.suppFileLoc}
                onChange={(e: any) => documentPreviewVM.onModalDropdownChange(e, 'suppFileLoc')}
                options={fileLocationDropdownOptions} />
        </div>
        , [docFirstPrintCriteria.suppFileLoc, documentPreviewVM, fileLocationDropdownOptions])

    return <><DialogModal style={{ minWidth: '650px', minHeight: '650px' }}
        appendTo='self'
        showButton={false}
        visible={isShowPrintPad}
        positions="center"
        resizable={false}
        header={
            <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">DOCUMENTS TO PRINT</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={documentPreviewVM.cancelConfirmModal} tooltipDisable={true} />
            </div>
        }
        dialogContent={
            <div className="im-charge-data-search-confirm-modal-content">

                <CriteriaItemContainer>
                    <span className="DocPrintLabel">{'Finalized By'}</span>
                    {memoFinalizedBy}
                </CriteriaItemContainer>

                <CriteriaItemContainer>
                    <span className="DocPrintLabel">{MODAL_CONST.PRINTER}</span>
                </CriteriaItemContainer>

                <CriteriaItemContainer>
                    <span className="DocPrintLabel">{MODAL_CONST.PRINTER_INV}</span>
                    {memoInvPrinter}{memoInvFileLoc}
                </CriteriaItemContainer>

                <CriteriaItemContainer>
                    <span className="DocPrintLabel">{MODAL_CONST.PRINTER_SUPP}</span>
                    {memoSuppDocPrinter}{memoSuppDocFileLoc}
                </CriteriaItemContainer>

            </div>
        }
        className="im-charge-data-search-confirm-modal"
        footer={
            <div className="im-charge-data-search-confirm-modal-footer">
                {/* <HPHButton label={'View'} size={'Small'} theme={'Secondary'} onClick={onViewClicked}/> */}
                <HPHButton label={'Print/Sent'} size={'Small'} theme={'Primary'} onClick={onPrintAndSentClicked} />
            </div>
        }
    /></>;
};

export default memo(DocumentPreviewFirstPrintModal);